<template>
  <div class="center" v-loading="loading">
    <div class="top">申请额度</div>
    <div class="ever">
      <div class="tex one" :class="active == 0 ? 'active' : 'item'">
        <i
          :style="
            active == 0
              ? { 'font-size': '28px', color: '#FFFFFF' }
              : { 'font-size': '28px', color: '#666666' }
          "
          class="hy-icon-bianji"
        ></i>
      </div>
      <div class="tex two" :class="active == 1 ? 'active' : 'item'">
        <i
          :style="
            active == 1
              ? { 'font-size': '28px', color: '#FFFFFF' }
              : { 'font-size': '28px', color: '#666666' }
          "
          class="hy-icon-jichuxinxi"
        ></i>
      </div>
      <div class="tex three" :class="active == 2 ? 'active' : 'item'">
        <i
          :style="
            active == 2
              ? { 'font-size': '28px', color: '#FFFFFF' }
              : { 'font-size': '28px', color: '#666666' }
          "
          class="hy-icon-shijian1"
        ></i>
      </div>
      <div class="tex four" :class="active == 3 ? 'active' : 'item'">
        <i
          :style="
            active == 3
              ? { 'font-size': '28px', color: '#FFFFFF' }
              : { 'font-size': '28px', color: '#666666' }
          "
          class="hy-icon-ziliaoshouce-xianxing"
        ></i>
      </div>
    </div>
    <div v-show="active == 0" class="inner">
      <el-form
        ref="applyform"
        :model="applyform"
        :rules="rules"
        label-width="120px"
      >
        <el-col :span="11">
          <el-form-item label="申请编号：" prop="applyCode">
            <el-input
              disabled
              size="small"
              placeholder="请输入申请编号"
              v-model="applyform.applyCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="申请产品名称：" prop="productName">
            <el-input
              disabled
              size="small"
              placeholder="请输入申请产品名称"
              v-model="applyform.productName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="申请日期：" prop="applyDate">
            <el-date-picker
              disabled
              size="small"
              class="time_input"
              v-model="applyform.applyDate"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择申请日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="融资企业名称：" prop="coreCompanyName">
            <el-input
              size="small"
              disabled
              placeholder="请输入融资企业名称"
              v-model="applyform.coreCompanyName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="申请金额：" prop="applyAmount">
            <el-input
              size="small"
              placeholder="请输入申请金额"
              maxlength="7"
              @keyup.native="
                applyform.applyAmount = proving(applyform.applyAmount)
              "
              v-model="applyform.applyAmount"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="remark">
            <el-input
              type="textarea"
              :rows="6"
              resize="none"
              placeholder="请输入内容"
              maxlength="400"
              show-word-limit
              size="small"
              v-model="applyform.remark"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <div v-show="active == 1" class="innero">
      <el-form
        ref="basicsform"
        :rules="rules"
        :model="basicsform"
        label-width="190px"
      >
        <el-col :span="12">
          <el-form-item label="企业名称：" prop="companyName">
            <el-input
              size="small"
              disabled
              placeholder="请输入企业名称"
              v-model="basicsform.companyName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册时间：" prop="registerTime">
            <el-date-picker
              size="small"
              v-model="basicsform.registerTime"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择注册时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社会信用代码：" prop="socialCode">
            <el-input
              size="small"
              disabled
              placeholder="请输入社会信用代码"
              v-model="basicsform.socialCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册资本(万元)：" prop="registerCapital">
            <el-input
              size="small"
              @input="basicsform.registerCapital=tonumber(basicsform.registerCapital)"
              maxlength="7"
              placeholder="请输入注册资本"
              v-model="basicsform.registerCapital"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册地址：" prop="registerAddress">
            <el-input
              size="small"
              maxlength="100"
              placeholder="请输入注册地址"
              v-model="basicsform.registerAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人姓名：" prop="legalName">
            <el-input
              size="small"
              disabled
              placeholder="请输入法人姓名"
              v-model="basicsform.legalName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人手机号：" prop="legalPhone">
            <el-input
              size="small"
              @input="basicsform.legalPhone=tonumber(basicsform.legalPhone)"
              maxlength="11"
              placeholder="请输入法人手机号"
              v-model="basicsform.legalPhone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人证件号码：" prop="legalIdcard">
            <el-input
              size="small"
              disabled
              placeholder="请输入法人证件号码"
              v-model="basicsform.legalIdcard"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业总资产(万元)：" prop="companyTotalAssets">
            <el-input
              size="small"
              @input="basicsform.companyTotalAssets=tonumber(basicsform.companyTotalAssets)"
              maxlength="7"
              placeholder="请输入企业总资产"
              v-model="basicsform.companyTotalAssets"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业负债总额：" prop="companyDebt">
            <el-input
              size="small"
              @input="basicsform.companyDebt=tonumber(basicsform.companyDebt)"
              maxlength="7"
              placeholder="请输入企业负债总额"
              v-model.number="basicsform.companyDebt"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际控制人姓名：" prop="actualControllerName">
            <el-input
              size="small"
              placeholder="请输入实际控制人姓名"
              v-model="basicsform.actualControllerName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="实际控制人证件号码："
            prop="actualControllerIdcard"
          >
            <el-input
              size="small"
              placeholder="请输入实际控制人证件号码"
              v-model="basicsform.actualControllerIdcard"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="前一年度销售收入：" prop="previousYearIncome">
            <el-input
              size="small"
              @input="basicsform.previousYearIncome=tonumber(basicsform.previousYearIncome)"
              maxlength="7"
              placeholder="请输入前一年度销售收入"
              v-model="basicsform.previousYearIncome"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际控制人手机号：" prop="actualControllerPhone">
            <el-input
              size="small"
              @input="basicsform.actualControllerPhone=tonumber(basicsform.actualControllerPhone)"
              maxlength="11"
              placeholder="请输入实际控制人手机号"
              v-model="basicsform.actualControllerPhone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="与核心企业合作年限："
            prop="companyCooperationYears"
          >
            <el-input
              size="small"
              placeholder="请输入与核心企业合作年限"
              @keyup.native="
                basicsform.companyCooperationYears = proving(
                  basicsform.companyCooperationYears
                )
              "
              maxlength="2"
              v-model="basicsform.companyCooperationYears"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="前两年度销售收入：" prop="twoYearsIncome">
            <el-input
              size="small"
              @input="basicsform.twoYearsIncome=tonumber(basicsform.twoYearsIncome)"
              maxlength="7"
              placeholder="请输入前两年度销售收入"
              v-model="basicsform.twoYearsIncome"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对公银行账号：" prop="publicBankAccount">
            <el-input
              size="small"
              @input="basicsform.publicBankAccount=tonumber(basicsform.publicBankAccount)"
              maxlength="28"
              placeholder="请输入对公银行账号"
              v-model="basicsform.publicBankAccount"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对公银行账户开户行：" prop="publicBankName">
            <el-input
              size="small"
              maxlength="20"
              placeholder="请输入对公银行账户开户行"
              v-model="basicsform.publicBankName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人银行卡卡号：" prop="legalBankAccount">
            <el-input
              size="small"
              placeholder="请输入法人银行卡卡号"
              @input="basicsform.legalBankAccount = tonumber(basicsform.legalBankAccount)"
              maxlength="28"
              v-model="basicsform.legalBankAccount"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人银行卡开户行：" prop="legalBankName">
            <el-input
              size="small"
              maxlength="20"
              placeholder="请输入法人银行卡开户行"
              v-model="basicsform.legalBankName"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <div v-show="active == 2" class="innerl">
      <el-form
        ref="historyform"
        :rules="rules"
        :model="historyform"
        label-width="250px"
      >
        <el-col :span="11">
          <el-form-item
            label="前两年度与买方交易规模(万元)："
            prop="twoYearsTradeSize"
          >
            <el-input
              size="small"
              placeholder="请输入前两年度与买方交易规模"
              @input="historyform.twoYearsTradeSize=tonumber(historyform.twoYearsTradeSize)"
              maxlength="7"
              v-model="historyform.twoYearsTradeSize"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            label="前一年度与买方交易规模(万元)："
            prop="previousYearTradeSize"
          >
            <el-input
              size="small"
              placeholder="请输入前一年度与买方交易规模"
              @input="historyform.previousYearTradeSize=tonumber(historyform.previousYearTradeSize)"
              maxlength="7"
              v-model.number="historyform.previousYearTradeSize"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            label="前三年度与买方交易规模(万元)："
            prop="threeYearsTradeSize"
          >
            <el-input
              size="small"
              placeholder="请输入前三年度与买方交易规模"
              @input="historyform.threeYearsTradeSize=tonumber(historyform.threeYearsTradeSize)"
              maxlength="7"
              v-model.number="historyform.threeYearsTradeSize"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            label="前一年度应收账款总额(万元)："
            prop="previousYearReceivePayment"
          >
            <el-input
              size="small"
              placeholder="请输入前一年度应收账款总额"
              @input="historyform.previousYearReceivePayment=tonumber(historyform.previousYearReceivePayment)"
              maxlength="7"
              v-model.number="historyform.previousYearReceivePayment"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="平均账期(月)：" prop="averageAccountPeriod">
            <el-input
              size="small"
              placeholder="请输入平均账期"
              @keyup.native="
                historyform.averageAccountPeriod = proving(
                  historyform.averageAccountPeriod
                )
              "
              maxlength="2"
              v-model="historyform.averageAccountPeriod"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            label="前一年度退换货比率(%)："
            prop="previousYearReturnRatio"
          >
            <el-input
              size="small"
              placeholder="请输入前一年度退换货比率"
              @keyup.native="
                historyform.previousYearReturnRatio = oninput(
                  historyform.previousYearReturnRatio
                )
              "
              v-model="historyform.previousYearReturnRatio"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="坏账率(%)：" prop="badDebtsRatio">
            <el-input
              size="small"
              placeholder="请输入坏账率"
              @keyup.native="
                historyform.badDebtsRatio = oninput(historyform.badDebtsRatio)
              "
              v-model="historyform.badDebtsRatio"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            label="前一年度纠纷比率(%)："
            prop="previousYearDisputeRatio"
          >
            <el-input
              @keyup.native="
                historyform.previousYearDisputeRatio = oninput(
                  historyform.previousYearDisputeRatio
                )
              "
              size="small"
              placeholder="请输入前一年度纠纷比率"
              v-model="historyform.previousYearDisputeRatio"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <div v-show="active == 3" class="innerw">
      <el-form
        ref="uploadform"
        :rules="rules"
        :model="uploadform"
        label-width="250px"
      >
        <el-col :span="11">
          <el-form-item label="营业执照：" prop="businessLicenseUrl">
            <el-upload
              class="avatar-uploader"
              disabled
              v-model="uploadform.businessLicenseUrl"
              action="#"
              :show-file-list="false"
              :http-request="shangchuan"
            >
              <img
                v-if="uploadform.businessLicenseUrl"
                :src="uploadform.businessLicenseUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div v-if="!uploadform.businessLicenseUrl" class="inner_text">
                用户认证营业执照
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="资料上传：" prop="receiptInvoiceUrl">
            <el-upload
              class="avatar-uploader"
              v-model="uploadform.receiptInvoiceUrl"
              action="#"
              :show-file-list="false"
              :http-request="shangchuanzip"
            >
              <div
                v-if="uploadform.receiptInvoiceUrl"
                class="avatar"
                style="line-height: 163px"
              >
                <img style="width: 50px; height: 40px" :src="rarimage" />
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div v-if="!uploadform.receiptInvoiceUrl" class="inner_text">
                请上传
              </div>
            </el-upload>
            <div style="font-size:12px;color:#666">
              <div>备注:上传格式应为压缩包ZIP格式</div>
              <div style="margin-left:27px">①法人身份证正反面</div>
              <div style="margin-left:27px">②股东会(借款)决议</div>
              <div style="margin-left:27px">③开户许可证</div>
              <div style="margin-left:27px">④公司章程</div>
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <div class="bottom">
      <el-button v-if="active == 0" @click="$router.go(-1)" size="medium"
        >取消</el-button
      >
      <el-button v-if="active != 0" @click="previousstep" size="medium"
        >上一步</el-button
      >
      <el-button
        v-if="active < 3"
        size="medium"
        type="primary"
        @click="nextstep"
        >下一步</el-button
      >
      <el-button v-if="active == 3" size="medium" type="primary" @click="submit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { applyQuota, placeFinanceOrder } from "@/api/home";
import { reqUploadfile } from "@/api/hy";
const { baseUrl } = require("@/config/index.js");
const refsList = ["applyform", "basicsform", "historyform", "uploadform"];
export default {
  data() {
    var valiregisterTime = (rule, value, callback) => {
      console.log(this.basicsform.registerTime);
      if (this.basicsform.registerTime) {
        let nowDate = Date.parse(new Date());
        let chooseDate = Date.parse(value);
        console.log(nowDate<chooseDate);
        console.log(chooseDate);
        if (nowDate < chooseDate) {
          callback(new Error("注册日期不能大于当前日期"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      rarimage: require("@/assets/img/rar.png"),
      baseUrl: baseUrl + "third/third/ossGetFile/",
      uploadform: {
        businessLicenseUrl: "",
        receiptInvoiceUrl: "",
      },
      applyform: {
        applyCode: "",
        coreCompanyName: "",
        productName: "",
        applyDate: "",
        applyAmount: "",
        remark: "",
      },
      historyform: {
        twoYearsTradeSize: "",
        previousYearTradeSize: "",
        threeYearsTradeSize: "",
        previousYearReceivePayment: "",
        averageAccountPeriod: "",
        previousYearReturnRatio: "",
        badDebtsRatio: "",
        previousYearDisputeRatio: "",
      },
      basicsform: {
        companyName: "",
        registerTime: "",
        previousYearIncome: "",
        socialCode: "",
        legalName: "",
        registerCapital: "",
        registerAddress: "",
        legalPhone: "",
        legalIdcard: "",
        companyTotalAssets: "",
        companyDebt: "",
        actualControllerName: "",
        actualControllerIdcard: "",
        actualControllerPhone: "",
        companyCooperationYears: "",
        twoYearsIncome: "",
        publicBankAccount: "",
        publicBankName: "",
        legalBankAccount: "",
        legalBankName: "",
      },
      accept: [".bmp", ".png", ".jpg", ".jpeg"],
      active: 0,
      jumpUrl: null,
      rules: {
        applyAmount: [
          { required: true, message: "请输入申请金额", trigger: "change" },
        ],
        coreCompanyName: [
          { required: true, message: "请选择融资企业名称", trigger: "change" },
        ],
        applyDate: [
          { required: true, message: "请选择申请日期", trigger: "change" },
        ],
        productName: [
          { required: true, message: "请输入申请产品名称", trigger: "change" },
        ],
        applyCode: [
          { required: true, message: "请输入申请编号", trigger: "change" },
        ],
        legalBankName: [
          {
            required: true,
            message: "请输入法人银行卡开户行",
            trigger: "change",
          },
          {
            pattern: /^[\u4e00-\u9fa5|0-9]/,
            message: "请输入中文或者数字",
            trigger: "blur",
          },
        ],
        legalBankAccount: [
          {
            required: true,
            message: "请输入法人银行卡卡号",
            trigger: "change",
          },
        ],
        publicBankName: [
          {
            required: true,
            message: "请输入对公银行账户开户行",
            trigger: "change",
          },
          {
            pattern: /^[\u4e00-\u9fa5|0-9]/,
            message: "请输入中文或者数字",
            trigger: "blur",
          },
        ],
        publicBankAccount: [
          { required: true, message: "请输入对公银行账号", trigger: "change" },
        ],
        twoYearsIncome: [
          {
            required: true,
            message: "请输入前两年度销售收入",
            trigger: "change",
          },
        ],
        companyCooperationYears: [
          {
            required: true,
            message: "请输入与核心企业合作年限",
            trigger: "change",
          },
        ],
        legalPhone: [
          {
            required: true,
            message: "请输入法人手机号",
            trigger: "change",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入有效手机号码",
            trigger: "blur",
          },
        ],
        actualControllerPhone: [
          {
            required: true,
            message: "请输入实际控制人手机号",
            trigger: "change",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入有效手机号码",
            trigger: "blur",
          },
        ],
        previousYearIncome: [
          {
            required: true,
            message: "请输入前一年度销售收入",
            trigger: "change",
          },
        ],
        actualControllerIdcard: [
          {
            required: true,
            message: "请输入实际控制人证件号码",
            trigger: "change",
          },
          {
            pattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效身份证号码",
            trigger: "blur",
          },
        ],
        actualControllerName: [
          {
            required: true,
            message: "请输入实际控制人姓名",
            trigger: "change",
          },
          {
            pattern: /^[\u4e00-\u9fa5]{2,20}$/,
            message: "请输入2到20位汉字",
            trigger: "blur",
          },
        ],
        companyDebt: [
          { required: true, message: "请输入企业负债总额", trigger: "change" },
        ],
        companyTotalAssets: [
          { required: true, message: "请输入企业总资产", trigger: "change" },
        ],
        legalName: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
          {
            pattern: /^[\u4e00-\u9fa5]{2,20}$/,
            message: "请输入2-20为汉字",
            trigger: "blur",
          },
        ],
        registerAddress: [
          { required: true, message: "请输入注册地址", trigger: "change" },
        ],
        registerCapital: [
          { required: true, message: "请输入注册资本", trigger: "change" },
        ],
        socialCode: [
          { required: true, message: "请输入社会信用代码", trigger: "change" },
        ],
        registerTime: [
          { required: true, message: "请选择注册时间", trigger: "change" },
          { validator: valiregisterTime, trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        receiptInvoiceUrl: [
          { required: true, message: "请上传资料上传", trigger: "change" },
        ],
        businessLicenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        twoYearsTradeSize: [
          {
            required: true,
            message: "请输入前两年度与买方交易规模",
            trigger: "change",
          },
        ],
        previousYearTradeSize: [
          {
            required: true,
            message: "请输入前一年度与买方交易规模",
            trigger: "change",
          },
        ],
        threeYearsTradeSize: [
          {
            required: true,
            message: "请输入前三年度与买方交易规模",
            trigger: "change",
          },
        ],
        previousYearReceivePayment: [
          {
            required: true,
            message: "请输入前一年度应收账款总额",
            trigger: "change",
          },
        ],
        averageAccountPeriod: [
          { required: true, message: "请输入平均账期", trigger: "change" },
        ],
        previousYearReturnRatio: [
          {
            required: true,
            message: "请输入前一年度退换货比率",
            trigger: "change",
          },
        ],
        badDebtsRatio: [
          { required: true, message: "请输入坏账率", trigger: "change" },
        ],
        previousYearDisputeRatio: [
          {
            required: true,
            message: "请输入前一年度纠纷比率",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getdetail();
  },
  watch: {
    jumpUrl() {
      if (this.jumpUrl) {
        window.open(this.jumpUrl, "_blank");
      }
      this.jumpUrl = null;
    },
  },
  methods: {
    async getdetail() {
      const res = await applyQuota({
        serviceGoodId: this.$route.query.serviceGoodId,
      });
      if (res.code == 0) {
        if (res.data.url) {
          window.open(res.data.url);
          this.$router.go(-1);
          return;
        }
        this.applyform.applyCode = res.data.applyCode;
        this.applyform.coreCompanyName = res.data.coreCompanyName;
        this.applyform.productName = res.data.productName;
        this.applyform.applyDate =
          res.data.applyDate.split("T")[0] +
          " " +
          res.data.applyDate.split("T")[1].split(".")[0];
        this.basicsform.companyName = res.data.companyName;
        this.basicsform.socialCode = res.data.socialCode;
        this.basicsform.legalIdcard = res.data.legalIdcard;
        this.basicsform.legalName = res.data.legalName;
        this.uploadform.businessLicenseUrl =
          res.data.businessLicenseUrl.indexOf("http") == -1
            ? this.baseUrl + res.data.businessLicenseUrl
            : res.data.businessLicenseUrl;
      }
    },
    shangchuanzip({ file }) {
      let reg = new RegExp(`^.+\.(zip)$`, "i");
      if (!reg.test(file.name)) {
        this.$message({
          message: `请上传zip格式的压缩包`,
          type: "error",
        });
        return;
      }
      let maxSize = 10;
      let size = file.size / 1024 / 1024 < maxSize;
      if (!size) {
        this.$message.error(`上传图片大小不能超过 ${maxSize}MB!`);
        return;
      }
      reqUploadfile(file).then((ret) => {
        if (ret.code == 0) {
          this.uploadform.receiptInvoiceUrl = this.baseUrl + ret.data;
        }
      });
    },
    shangchuan({ file }) {
      let type = this.accept.map((item) => item.split(".")[1]);
      let reg = new RegExp(`^.+\.(${type.join("|")})$`, "i");
      if (!reg.test(file.name)) {
        this.$message({
          message: `请上传${type.join("、")}格式的图片`,
          type: "error",
        });
        return false;
      }
      let maxSize = 10;
      let size = file.size / 1024 / 1024 < maxSize;
      if (!size) {
        this.$message.error(`上传图片大小不能超过 ${maxSize}MB!`);
        return false;
      }
      reqUploadfile(file).then((ret) => {
        if (ret.code == 0) {
          this.uploadform.businessLicenseUrl = this.baseUrl + ret.data;
        }
      });
    },
    nextstep() {
      this.$refs[refsList[this.active]].validate((valid) => {
        if (valid) {
          this.active++;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 限制只能输入0.01到99.99
    oninput(num) {
      let str = num;
      const len1 = str.substr(0, 1);
      const len2 = str.substr(1, 1);
      if (str.length > 1 && len1 === "0" && len2 !== ".") {
        str = str.substr(1, str.length - 1);
      }
      if (str > 99.99 || str === "0.00") {
        str = str.substring(0, str.length - 1);
      }
      str = str.replace(/^\D*([0-9]{0,2}\.?\d{0,2})?.*$/, "$1");
      return str;
    },
    tonumber(val) {
      let str = val+''
      console.log(str);
      str = str.replace(/[^\d^\.]/g,'')
      return str
    },
    previousstep() {
      this.active--;
    },
    submit() {
      this.$refs[refsList[this.active]].validate((valid) => {
        if (valid) {
          this.loading = true;
          placeFinanceOrder({
            serviceGoodId: this.$route.query.serviceGoodId,
            ...this.applyform,
            ...this.basicsform,
            ...this.historyform,
            ...this.uploadform,
          }).then((res) => {
            if (res.code == 0) {
              this.jumpUrl = res.data;
              this.$router.push({
                path: "/usbanking",
              });
              this.loading = false;
            } else {
              this.$message.error(res.message);
              this.loading = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 限制正整数
    proving(val) {
      val = val.replace(/[^\.\d]/g, "");
      val = val.replace(".", "");
      return val;
    },
  },
};
</script>
<style>
.time_input {
  width: 100% !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.inner_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 227px;
  height: 138px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 227px;
  height: 138px;
  display: block;
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .top {
    text-align: center;
    height: 119px;
    font-size: 24px;
    font-weight: bold;
    color: #030303;
    line-height: 119px;
    border-bottom: 4px solid #f7f7f7;
  }
  .ever {
    position: relative;
    top: -37px;
    height: 70px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    .tex ::after {
      position: absolute;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      top: 55px;
    }
    .one ::after {
      content: "申请信息";
      left: 0;
    }
    .two ::after {
      content: "基础信息";
      left: 0;
    }
    .three ::after {
      content: "历史交易信息";
      left: -15px;
      width: 100px;
    }
    .four ::after {
      content: "资料确认";
      left: 0;
    }
    .item {
      position: relative;
      width: 70px;
      height: 70px;
      background: #ffffff;
      text-align: center;
      line-height: 70px;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
    }
    .active {
      position: relative;
      width: 70px;
      height: 70px;
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      text-align: center;
      line-height: 70px;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
    }
  }
  .inner {
    width: calc(100% - 480px);
    margin: 0 auto;
    height: 400px;
  }
  .innero {
    width: calc(100% - 373px);
    margin: 0 auto;
    height: 700px;
  }
  .innerl {
    width: calc(100% - 180px);
    margin: 0 auto;
    height: 300px;
  }
  .innerw {
    padding-top: 80px;
    width: calc(100% - 200px);
    margin: 0 auto;
    height: 300px;
  }
  .bottom {
    display: flex;
    justify-content: center;
    margin: 50px 0 20px 0;
  }
}
::v-deep{
  .el-form-item__content{
    line-height: 24px;
  }
}
</style>
